import React, { useEffect, useState } from "react";
import { Modal } from "@material-ui/core";
import SpalshScreen_After from "./SplashScreen_After";
import "./SplashScreen.css";

const SplashScreen = (props) => {
  const [hasDoneAnimnation, setHasDoneAnimation] = useState(false);

  const [checkForClose, setCheckForClose] = useState(false);

  const [gifMaker, setGifMaker] = useState({
    src: props.src,
    loader: props.src,
  });
  const doOnClose = () => {
    setCheckForClose(false);
    props.onClose();
  };

  useEffect(() => {
    setTimeout(() => {
      let xx = document.getElementsByClassName("gif_gif")[0];
      xx.src = xx.src + "?a=" + Math.random();
      setHasDoneAnimation(true);
    }, 6951);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setCheckForClose(true);
    }, 12000);
  }, []);

  useEffect(() => {
    if (checkForClose === true) doOnClose();
  }, [checkForClose]);

  const resolveImgSrc = (n) => {
    if (n == 1) return "./dices/dice1.png";
    if (n == 2) return "./dices/dice2.png";
    if (n == 3) return "./dices/dice3.png";
    if (n == 4) return "./dices/dice4.png";
    if (n == 5) return "./dices/dice5.png";
    if (n == 6) return "./dices/dice6.png";
  };

  const conditionalRender = () => {
    if (!hasDoneAnimnation) {
      return (
        <>
          <div className="splash_screen_gif_wrap">
            <img src={gifMaker.src} className="gif_gif" />
          </div>
        </>
      );
    } else if (hasDoneAnimnation) {
      return (
        <SpalshScreen_After
          bookResponse={props.bookResponse}
          resolveImgSrc={resolveImgSrc}
        />
      );
    }
  };

  return (
    <Modal
      open={props.open}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          doOnClose();
        }
      }}
      className="splash_modal"
    >
      <div
        className={
          hasDoneAnimnation ? "modal_container_res" : "modal_container"
        }
      >
        {conditionalRender()}
      </div>
    </Modal>
  );
};

export default SplashScreen;
