import logo from "./logo.svg";
import "./App.css";
import React, { useEffect, useState } from "react";
import {
  AddToStake,
  BookTicket,
  DeleteMarketFromStake,
  getBalance,
  GetHistory,
  getTotalBet,
  getWinAmount,
  InitializeGame,
  renderGifSrc,
} from "./Utilities";
import PlayButtons from "./Components/PlayButtons";
import SplashScreen from "./Components/SplashScreen";
import HistoryDropDown from "./Components/HistoryDropDown";
import ModalHistory from "./Components/ModalHistory";
import PlayButtons_Mobile from "./Components/PlayButtons_Mobile";
import ModalUser from "./Components/ModalUser";
import MobileDropDown from "./Components/MobileDropDown";
import LogoffDropDown from "./Components/LogoffDropDown";

function App() {
  const [chipSelection, setChipSelection] = useState([0, 1, 0, 0, 0, 0]);
  const handleChipSelection = (index) => {
    const aux = [0, 0, 0, 0, 0, 0];
    aux[index] = 1;
    setChipSelection(aux);
  };

  const [stake, setStake] = useState([]);

  const [tkn, setTkn] = useState(null);
  const [gameInit, setGameInit] = useState(null);

  useEffect(() => {
    // InitializeGame(setTkn, setGameInit);
    document.oncontextmenu = function () {
      return false;
    };
  }, []);

  const [clock, setClock] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setClock(new Date());
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const checkForChipsInStake = (marketId) => {
    if (stake.length > 0) {
      for (let i = 0; i < stake.length; i++)
        if (stake[i].id === marketId) return true;
    }
    return false;
  };

  const getFullValueInStake = (marketId) => {
    let final = 0;
    if (stake.length > 0) {
      for (let i = 0; i < stake.length; i++) {
        if (stake[i].id === marketId) final += stake[i].amount;
      }
    }
    return final;
  };

  const renderSpecChip = (marketId) => {
    let sum = getFullValueInStake(marketId);
    if (sum < 5) return "./chips/whiteN.png";
    if (sum < 10) return "./chips/redN.png";
    if (sum < 25) return "./chips/blueN.png";
    if (sum < 50) return "./chips/greenN.png";
    if (sum < 100) return "./chips/orangeN.png";
    if (sum >= 100) return "./chips/blackN.png";
  };

  const [json, setJson] = useState(null);
  const [bookResponse, setBookResponse] = useState(null);

  useEffect(() => {
    if (json != null) BookTicket(tkn, json, setBookResponse);
  }, [json]);

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
    setBookResponse(null);
  };

  useEffect(() => {
    if (bookResponse != null) {
      handleOpenModal();
    }
  }, [bookResponse]);

  const [dropDown, setDropDown] = useState(false);
  const toggleDropDown = () => setDropDown(!dropDown);

  const [smallHistory, setSmallHistory] = useState(null);
  const [fullHistory, setFullHistory] = useState(null);

  useEffect(() => {
    GetHistory(5, tkn, setSmallHistory);
    GetHistory(null, tkn, setFullHistory);
  }, []);

  const [histModal, setHistModal] = useState(false);

  const handleHistOpenModal = () => setHistModal(true);
  const handleHistCloseModal = () => setHistModal(false);

  const [handleUserModal, setHandleUserModal] = useState(false);
  const handleOpenUserModal = () => setHandleUserModal(true);
  const handleCloseUserModal = () => setHandleUserModal(false);

  const [userName, setUserName] = useState(null);
  const [pass, setPass] = useState(null);
  const handleUserChange = (newuser) => setUserName(newuser);
  const handlePassword = (newpass) => setPass(newpass);

  const [mobileMenuDropDown, setMobileMenuDropdown] = useState(false);
  const toggleMobileMenuDrop = () => setMobileMenuDropdown(!mobileMenuDropDown);

  const [logoffDropDown, setLogoffDropDown] = useState(false);
  const toggleLogoff = () => setLogoffDropDown(!logoffDropDown);

  const checkDevice = () => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      return true;
    }
    return false;
  };

  const [isFullScreen, setIsFullScreen] = useState(false);

  useEffect(() => {
    const root = document.documentElement;

    if (isFullScreen) {
      if (
        root.requestFullscreen ||
        root.webkitRequestFullscreen ||
        root.msRequestFullscreen
      ) {
        const requestFullscreen =
          root.requestFullscreen ||
          root.webkitRequestFullscreen ||
          root.msRequestFullscreen;
        requestFullscreen.call(root);
      }
    } else {
      if (
        document.exitFullscreen ||
        document.webkitExitFullscreen ||
        document.msExitFullscreen
      ) {
        const exitFullscreen =
          document.exitFullscreen ||
          document.webkitExitFullscreen ||
          document.msExitFullscreen;
        exitFullscreen.call(document);
      }
    }
  }, [isFullScreen]);

  const handleFullScreenToggle = () => {
    setIsFullScreen(!isFullScreen);
  };

  const renderTiltGif = () => {
    if (checkDevice() && window.innerHeight > window.innerWidth) {
      return (
        <div className="please_change_root">
          <div className="please_change_header">LANDSCAPE MODE REQUIRED!</div>
          <div className="please_change_gif">
            <img src="./rotate_phone.gif" className="rotate_gif" />
          </div>
          <div className="please_change_info">
            Please rotate your device to access landscape mode
          </div>
        </div>
      );
    } else
      return (
        <>
          <div className="top_header">
            {tkn != null ? (
              <div className="left_header">
                <div
                  className="left_history"
                  onClick={() => {
                    GetHistory(5, tkn, setSmallHistory);
                    toggleDropDown();
                  }}
                >
                  HISTORY
                </div>
                <div className="left_balance">
                  BALANCE: {getBalance(gameInit, bookResponse)}{" "}
                  {gameInit.currency}
                </div>
                <div className="left_bet">
                  TOTAL BET: {getTotalBet(stake)} {gameInit.currency}
                </div>
                <div className="left_win">
                  WINS: {getWinAmount(bookResponse)} {gameInit.currency}
                </div>
              </div>
            ) : (
              <div
                className="left_history"
                style={{
                  paddingLeft: "1.5rem",
                  paddingBottom: ".7rem",
                  color: "burlywood",
                }}
              >
                Please Login to play the Game!
              </div>
            )}
            <div className="right_header">
              <div className="right_clock">
                TIME:{" "}
                {clock.toLocaleTimeString([], {
                  hour12: false,
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </div>
              <div className="right_icons">
                {tkn && (
                  <div className="right_icon">
                    <img src="./icons/info.png" />
                  </div>
                )}
                {tkn && (
                  <div className="right_icon">
                    <img src="./icons/menu.png" />
                  </div>
                )}
                {tkn && (
                  <div className="right_icon">
                    <img src="./icons/sound.png" />
                  </div>
                )}
                {tkn && (
                  <div
                    className="right_icon"
                    onClick={() => {
                      handleFullScreenToggle();
                    }}
                  >
                    <img src="./icons/fullscreen.png" />
                  </div>
                )}
                <div className="right_icon">
                  {tkn ? (
                    <div
                      style={{ paddingTop: "1rem" }}
                      onClick={() => toggleLogoff()}
                    >
                      Hello, {userName}
                    </div>
                  ) : (
                    <img
                      src="./icons/user.png"
                      onClick={() => handleOpenUserModal()}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <>
            <div
              className="betzone_2"
              onClick={() => {
                setDropDown(false);
                setMobileMenuDropdown(false);
                setLogoffDropDown(false);
                AddToStake(2, stake, setStake, chipSelection);
              }}
            ></div>
            <div
              className="betzone_3"
              onClick={() => {
                setDropDown(false);
                setLogoffDropDown(false);
                setMobileMenuDropdown(false);
                AddToStake(3, stake, setStake, chipSelection);
              }}
            ></div>
            <div
              className="betzone_4"
              onClick={() => {
                setDropDown(false);
                setLogoffDropDown(false);
                setMobileMenuDropdown(false);
                AddToStake(4, stake, setStake, chipSelection);
              }}
            ></div>
            <div
              className="betzone_5"
              onClick={() => {
                setDropDown(false);
                setLogoffDropDown(false);
                setMobileMenuDropdown(false);
                AddToStake(5, stake, setStake, chipSelection);
              }}
            ></div>
            <div
              className="betzone_6"
              onClick={() => {
                setDropDown(false);
                setLogoffDropDown(false);
                setMobileMenuDropdown(false);
                AddToStake(6, stake, setStake, chipSelection);
              }}
            ></div>

            <div
              className="betzone_8"
              onClick={() => {
                setDropDown(false);
                setLogoffDropDown(false);
                setMobileMenuDropdown(false);
                AddToStake(8, stake, setStake, chipSelection);
              }}
            ></div>
            <div
              className="betzone_9"
              onClick={() => {
                setDropDown(false);
                setLogoffDropDown(false);
                setMobileMenuDropdown(false);
                AddToStake(9, stake, setStake, chipSelection);
              }}
            ></div>
            <div
              className="betzone_10"
              onClick={() => {
                setDropDown(false);
                setLogoffDropDown(false);
                setMobileMenuDropdown(false);
                AddToStake(10, stake, setStake, chipSelection);
              }}
            ></div>
            <div
              className="betzone_11"
              onClick={() => {
                setDropDown(false);
                setLogoffDropDown(false);
                setMobileMenuDropdown(false);
                AddToStake(11, stake, setStake, chipSelection);
              }}
            ></div>
            <div
              className="betzone_12"
              onClick={() => {
                setDropDown(false);
                setLogoffDropDown(false);
                setMobileMenuDropdown(false);
                AddToStake(12, stake, setStake, chipSelection);
              }}
            ></div>

            <div
              className="betzone_u7"
              onClick={() => {
                setDropDown(false);
                setLogoffDropDown(false);
                setMobileMenuDropdown(false);
                AddToStake(100, stake, setStake, chipSelection);
              }}
            ></div>
            <div
              className="betzone_7"
              onClick={() => {
                setDropDown(false);
                setLogoffDropDown(false);
                setMobileMenuDropdown(false);
                AddToStake(101, stake, setStake, chipSelection);
              }}
            ></div>
            <div
              className="betzone_o7"
              onClick={() => {
                setDropDown(false);
                setLogoffDropDown(false);
                setMobileMenuDropdown(false);
                AddToStake(102, stake, setStake, chipSelection);
              }}
            ></div>
          </>

          <div className="bottom_chips">
            <div
              className={
                chipSelection.indexOf(1) === 0
                  ? "chip_container_selected white_chip"
                  : "chip_container white_chip"
              }
            >
              <img
                src="./chips/whiteN.png"
                onClick={() => handleChipSelection(0)}
              />
              <span>1</span>
            </div>

            <div
              className={
                chipSelection.indexOf(1) === 1
                  ? "chip_container_selected"
                  : "chip_container"
              }
            >
              <img
                src="./chips/redN.png"
                onClick={() => handleChipSelection(1)}
              />
              <span>5</span>
            </div>
            <div
              className={
                chipSelection.indexOf(1) === 2
                  ? "chip_container_selected"
                  : "chip_container"
              }
            >
              <img
                src="./chips/blueN.png"
                onClick={() => handleChipSelection(2)}
              />
              <span>10</span>
            </div>
            <div
              className={
                chipSelection.indexOf(1) === 3
                  ? "chip_container_selected"
                  : "chip_container"
              }
            >
              <img
                src="./chips/greenN.png"
                onClick={() => handleChipSelection(3)}
              />
              <span>25</span>
            </div>
            <div
              className={
                chipSelection.indexOf(1) === 4
                  ? "chip_container_selected"
                  : "chip_container"
              }
            >
              <img
                src="./chips/orangeN.png"
                onClick={() => handleChipSelection(4)}
              />
              <span>50</span>
            </div>
            <div
              className={
                chipSelection.indexOf(1) === 5
                  ? "chip_container_selected"
                  : "chip_container"
              }
            >
              <img
                src="./chips/blackN.png"
                onClick={() => handleChipSelection(5)}
              />
              <span>100</span>
            </div>
          </div>

          {stake.length > 0 && (
            <PlayButtons
              stake={stake}
              setStake={setStake}
              setJson={setJson}
              gameInit={gameInit}
              tkn={tkn}
            />
          )}
          {stake.length > 0 && (
            <PlayButtons_Mobile
              stake={stake}
              setStake={setStake}
              setJson={setJson}
              tkn={tkn}
              gameInit={gameInit}
            />
          )}

          {checkForChipsInStake(2) && (
            <div
              className="betzone_2_chips"
              onContextMenu={(e) => {
                e.preventDefault();
                DeleteMarketFromStake(2, stake, setStake);
              }}
              onClick={() => {
                AddToStake(2, stake, setStake, chipSelection);
              }}
            >
              <img src={renderSpecChip(2)} />
              <div className={getFullValueInStake(2) < 5 ? "white_chip" : null}>
                {getFullValueInStake(2)}
              </div>
            </div>
          )}
          {checkForChipsInStake(3) && (
            <div
              className="betzone_3_chips"
              onContextMenu={(e) => {
                e.preventDefault();
                DeleteMarketFromStake(3, stake, setStake);
              }}
              onClick={() => {
                AddToStake(3, stake, setStake, chipSelection);
              }}
            >
              <img src={renderSpecChip(3)} />
              <div className={getFullValueInStake(3) < 5 ? "white_chip" : null}>
                {getFullValueInStake(3)}
              </div>
            </div>
          )}
          {checkForChipsInStake(4) && (
            <div
              className="betzone_4_chips"
              onContextMenu={(e) => {
                e.preventDefault();
                DeleteMarketFromStake(4, stake, setStake);
              }}
              onClick={() => {
                AddToStake(4, stake, setStake, chipSelection);
              }}
            >
              <img src={renderSpecChip(4)} />
              <div className={getFullValueInStake(4) < 5 ? "white_chip" : null}>
                {getFullValueInStake(4)}
              </div>
            </div>
          )}
          {checkForChipsInStake(5) && (
            <div
              className="betzone_5_chips"
              onContextMenu={(e) => {
                e.preventDefault();
                DeleteMarketFromStake(5, stake, setStake);
              }}
              onClick={() => {
                AddToStake(5, stake, setStake, chipSelection);
              }}
            >
              <img src={renderSpecChip(5)} />
              <div className={getFullValueInStake(5) < 5 ? "white_chip" : null}>
                {getFullValueInStake(5)}
              </div>
            </div>
          )}
          {checkForChipsInStake(6) && (
            <div
              className="betzone_6_chips"
              onContextMenu={(e) => {
                e.preventDefault();
                DeleteMarketFromStake(6, stake, setStake);
              }}
              onClick={() => {
                AddToStake(6, stake, setStake, chipSelection);
              }}
            >
              <img src={renderSpecChip(6)} />
              <div className={getFullValueInStake(6) < 5 ? "white_chip" : null}>
                {getFullValueInStake(6)}
              </div>
            </div>
          )}
          {checkForChipsInStake(8) && (
            <div
              className="betzone_8_chips"
              onContextMenu={(e) => {
                e.preventDefault();
                DeleteMarketFromStake(8, stake, setStake);
              }}
              onClick={() => {
                AddToStake(8, stake, setStake, chipSelection);
              }}
            >
              <img src={renderSpecChip(8)} />
              <div className={getFullValueInStake(8) < 5 ? "white_chip" : null}>
                {getFullValueInStake(8)}
              </div>
            </div>
          )}
          {checkForChipsInStake(9) && (
            <div
              className="betzone_9_chips"
              onContextMenu={(e) => {
                e.preventDefault();
                DeleteMarketFromStake(9, stake, setStake);
              }}
              onClick={() => {
                AddToStake(9, stake, setStake, chipSelection);
              }}
            >
              <img src={renderSpecChip(9)} />
              <div className={getFullValueInStake(9) < 5 ? "white_chip" : null}>
                {getFullValueInStake(9)}
              </div>
            </div>
          )}
          {checkForChipsInStake(10) && (
            <div
              className="betzone_10_chips"
              onContextMenu={(e) => {
                e.preventDefault();
                DeleteMarketFromStake(10, stake, setStake);
              }}
              onClick={() => {
                AddToStake(10, stake, setStake, chipSelection);
              }}
            >
              <img src={renderSpecChip(10)} />
              <div
                className={getFullValueInStake(10) < 5 ? "white_chip" : null}
              >
                {getFullValueInStake(10)}
              </div>
            </div>
          )}
          {checkForChipsInStake(11) && (
            <div
              className="betzone_11_chips"
              onContextMenu={(e) => {
                e.preventDefault();
                DeleteMarketFromStake(11, stake, setStake);
              }}
              onClick={() => {
                AddToStake(11, stake, setStake, chipSelection);
              }}
            >
              <img src={renderSpecChip(11)} />
              <div
                className={getFullValueInStake(11) < 5 ? "white_chip" : null}
              >
                {getFullValueInStake(11)}
              </div>
            </div>
          )}
          {checkForChipsInStake(12) && (
            <div
              className="betzone_12_chips"
              onContextMenu={(e) => {
                e.preventDefault();
                DeleteMarketFromStake(12, stake, setStake);
              }}
              onClick={() => {
                AddToStake(12, stake, setStake, chipSelection);
              }}
            >
              <img src={renderSpecChip(12)} />
              <div
                className={getFullValueInStake(12) < 5 ? "white_chip" : null}
              >
                {getFullValueInStake(12)}
              </div>
            </div>
          )}
          {checkForChipsInStake(100) && (
            <div
              className="betzone_100_chips"
              onContextMenu={(e) => {
                e.preventDefault();
                DeleteMarketFromStake(100, stake, setStake);
              }}
              onClick={() => {
                AddToStake(100, stake, setStake, chipSelection);
              }}
            >
              <img src={renderSpecChip(100)} />
              <div
                className={getFullValueInStake(100) < 5 ? "white_chip" : null}
              >
                {getFullValueInStake(100)}
              </div>
            </div>
          )}
          {checkForChipsInStake(101) && (
            <div
              className="betzone_101_chips"
              onContextMenu={(e) => {
                e.preventDefault();
                DeleteMarketFromStake(101, stake, setStake);
              }}
              onClick={() => {
                AddToStake(101, stake, setStake, chipSelection);
              }}
            >
              <img src={renderSpecChip(101)} />
              <div
                className={getFullValueInStake(101) < 5 ? "white_chip" : null}
              >
                {getFullValueInStake(101)}
              </div>
            </div>
          )}
          {checkForChipsInStake(102) && (
            <div
              className="betzone_102_chips"
              onContextMenu={(e) => {
                e.preventDefault();
                DeleteMarketFromStake(102, stake, setStake);
              }}
              onClick={() => {
                AddToStake(102, stake, setStake, chipSelection);
              }}
            >
              <img src={renderSpecChip(102)} />
              <div
                className={getFullValueInStake(102) < 5 ? "white_chip" : null}
              >
                {getFullValueInStake(102)}
              </div>
            </div>
          )}

          {bookResponse != null && openModal === true ? (
            <SplashScreen
              open={openModal}
              onClose={handleCloseModal}
              bookResponse={bookResponse}
              src={renderGifSrc(
                bookResponse.diceRoll.split(":")[0],
                bookResponse.diceRoll.split(":")[1]
              )}
            />
          ) : null}
          {dropDown && (
            <HistoryDropDown
              hist={smallHistory}
              tkn={tkn}
              setFullHistory={setFullHistory}
              openModal={handleHistOpenModal}
            />
          )}
          {mobileMenuDropDown && (
            <MobileDropDown
              tkn={tkn}
              setTkn={setTkn}
              setStake={setStake}
              setFullHistory={setFullHistory}
              openModal={handleHistOpenModal}
              handleOpenUserModal={handleOpenUserModal}
              setMobileMenuDropdown={setMobileMenuDropdown}
            />
          )}
          {histModal && (
            <ModalHistory
              open={histModal}
              onClose={handleHistCloseModal}
              hist={fullHistory}
              tkn={tkn}
            />
          )}
          {logoffDropDown && (
            <LogoffDropDown
              setTkn={setTkn}
              tkn={tkn}
              setStake={setStake}
              setFullHistory={setFullHistory}
              openModal={handleHistOpenModal}
              setLogoffDropDown={setLogoffDropDown}
            />
          )}
          {handleUserModal && (
            <ModalUser
              open={handleUserModal}
              onClose={handleCloseUserModal}
              userName={userName}
              changeUser={handleUserChange}
              pass={pass}
              changePass={handlePassword}
              setTkn={setTkn}
              setGame={setGameInit}
            />
          )}

          {tkn != null ? (
            <div className="mobile_top_header">
              <div className="mobile_header_balance">
                BALANCE: {getBalance(gameInit, bookResponse)}{" "}
                {gameInit.currency}
              </div>
              <div className="mobile_header_wins">
                WINS: {getWinAmount(bookResponse)} {gameInit.currency}
              </div>
              <div className="mobile_header_tbet">
                TOTAL BET: {getTotalBet(stake)} {gameInit.currency}
              </div>
            </div>
          ) : (
            <div className="mobile_top_header">
              Please Login to play the Game!
            </div>
          )}
          <div
            className="mobile_left_burger"
            onClick={() => toggleMobileMenuDrop()}
          >
            <img src="./icons/menu.png" />
          </div>
          <div
            className="mobile_right_fullscreen"
            onClick={() => handleFullScreenToggle()}
          >
            <img src="./icons/fullscreen.png" />
          </div>
        </>
      );
  };

  return <div className="App">{renderTiltGif()}</div>;
}

export default App;
