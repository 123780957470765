import React, { useEffect } from "react";

const SpalshScreen_After = (props) => {
  const renderAfterImage = () => {
    if (props.bookResponse.winAmount === 0)
      return <img src="./dices/end_lost.png" className="end_message_lost" />;
    else return <img src="./dices/end_win.png" className="end_message" />;
  };

  useEffect(() => {
    if (props.bookResponse.winAmount > 0) {
      let xx = new Audio("./audio/jackpot1.mp3");
      xx.play();
    }
  }, []);

  return (
    <div className="splash_after_root">
      {renderAfterImage()}
      <img
        src={props.resolveImgSrc(
          parseInt(props.bookResponse.diceRoll.split(":")[0])
        )}
        width="60px"
        height="60px"
        className="firstdice"
      />
      <img
        src={props.resolveImgSrc(
          parseInt(props.bookResponse.diceRoll.split(":")[1])
        )}
        width="60px"
        height="60px"
        className="seconddice"
      />
      {props.bookResponse.winAmount > 0 && (
        <div className="how_much_to_win">{props.bookResponse.winAmount}</div>
      )}
    </div>
  );
};

export default SpalshScreen_After;
