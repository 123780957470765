import React from "react";
import { ClearSelection, GetHistory } from "../Utilities";

const LogoffDropDown = (props) => {
  return (
    <div className="logoff_dropdown">
      <ul>
        <li
          className="dropdown_item"
          onClick={() => {
            props.setTkn(null);
            ClearSelection(props.setStake);
            props.setLogoffDropDown(false);
          }}
        >
          SIGN OUT
        </li>
        <li
          className="dropdown_item"
          onClick={(e) => {
            e.preventDefault();
            GetHistory(null, props.tkn, props.setFullHistory);
            props.openModal();
          }}
        >
          HISTORY
        </li>
      </ul>
    </div>
  );
};

export default LogoffDropDown;
